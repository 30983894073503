import React from 'react'
import './Intro.css'
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import Vector1 from '../../img/Vector1.png';
import Vector2 from '../../img/Vector2.png';
import boy from '../../img/boy.png';
import thumbup from '../../img/thumbup.png';
import Crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import { themeContext } from '../../Context';
import { useContext } from "react";
import { motion } from 'framer-motion';

const Intro = () => {

  const transition = {duration : 2, type: 'spring'}

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="intro">
        <div className="i-left">
            <div className="i-name">
                <span style={{color: darkMode? 'white': ''}}>Hello! I'm</span>
                <span>Sindhu Nugroho</span>
                <span>Currently Teaching Coding with STEM Standard and a Branch Manager of Semarang, having experience in teaching Computer Science teacher and robotics coach in the only SPK (international school) in Purwokerto named Puhua School.
                </span>
            </div>
            <a href="https://api.whatsapp.com/send/?phone=6283150043326&text&type=phone_number&app_absent=0" target='_blank'>

            <button className="button i-button">Hire Me</button>
            </a>
            <div className="i-icons">
                <a href='https://github.com/sindhu-nugroho' target='_blank'>

                <img src={Github} alt="" />
                </a>

                <a href="https://www.linkedin.com/in/sindhu-nugroho-s-kom-725755100/" target='_blank'>

                <img src={LinkedIn} alt="" />
                </a>

                <a href="https://instagram.com/sindhunugroho_?igshid=ZDdkNTZiNTM=" target='_blank'>

                <img src={Instagram} alt="" />
                </a>
            </div>

        </div>
        <div className="i-right">
            <img src={Vector1} alt="" />
            <img src={Vector2} alt="" />
            <img src={boy} alt="" />
            <motion.img
            initial={{left: '-36%'}}
            whileInView={{left: '-24%'}}
            transition={transition}
            src={glassesimoji} 
            alt=""/>
            <motion.div 
            initial={{top: '-4%', left: '74%'}}
            whileInView={{left: '68%'}}
            transition={transition}
            style={{top: '-4%', left: '68%'}}
            className='floating-div'
            >
                <FloatingDiv image={Crown} txt1='Cybersecurity'/>
            </motion.div>
            <motion.div 
            initial={{left:'9rem', top: '18rem'}}
            whileInView={{left: '0rem'}}
            transition={transition}
            style={{top: '18rem', left: '0rem'}}
            className='floating-div'
            >
                <FloatingDiv image={thumbup} txt1='Robotics'/>
            </motion.div>

            {/*blur div*/}
            <div className='blur' style={{ background: "rgb (238 210 255)" }}></div>
            <div className='blur' style={{ background: '#C1F5FF', top: '17rem', width: '21rem', height: '11rem', left: '-9rem'}}></div>

        </div>
    </div>
  )
}

export default Intro