import React from 'react'
import './Services.css'
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Card from '../Card/Card';
import Resume from './resume.pdf'
import { themeContext } from '../../Context';
import { useContext } from "react";
import { motion } from 'framer-motion';

const Services = () => {

  const transition = {duration : 1, type: 'spring'}

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="services" id='services'>

        {/* left side */}

        <div className="awesome">
            <span style={{color: darkMode? 'white': ''}}>I Do Provide</span>
            <span>Services</span>
            <spane>Outside of teaching hours i do provide several lessons!
            <br/>
            And also sroject services.
            </spane>
            <a href={Resume} download>

            <button className="button s-button">Download CV</button>
            </a>
            <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>

        {/* right side */}

        <div className="cards">
            
            <motion.div 
            whileInView={{left: '14rem'}}
            initial={{left: '25rem'}}
            transition={transition}
            style={{left: '10rem' }}>
                <Card
                emoji = {HeartEmoji}
                heading = {'Multimedia'}
                detail = {"Figma, Photoshop, Illustrator, Canva, Premiere Pro, after effect"}

                />
            </motion.div>

            <motion.div 
            initial={{left: '-11rem', top: '12rem'}}
            whileInView={{left: '-4rem'}}
            transition={transition}
            style={{ top: "12rem", left: "-4rem" }}>
                <Card
                emoji= {Glasses}
                heading={'Web Developer'}
                detail={"Html, Css, Javascript, ReactJS, Laravel"}
                />
            </motion.div>

            <motion.div 
            initial={{ top: "19rem", left: "25rem" }}
            whileInView={{ left: "12rem" }}
            transition={transition}
            style={{ top: "19rem", left: "12rem" }}>
                <Card
                emoji= {Humble}
                heading={'Instructor'}
                detail={"Programming, Robotics, Graphic Design"}
                />
            </motion.div>
            <div className="blur s-blur2" style={{ background: "var(--purple)" }}></div>
        </div>
    </div>
  )
}

export default Services