import React from 'react'
import './Navbar.css'
import stem from '../../img/stem.png';
import Toogle from '../Toogle/Toogle'
import {Link} from 'react-scroll'


const Navbar = () => {
  return (
    <div className="n-wrapper">
        <div className="n-left">
            <div className="n-name"><img src={stem} alt="" /></div>
            <Toogle/>
        </div>
        <div className="n-right">
          <div className="n-list">
            <ul style={{listStyleType: 'none'}}>
              <li>
            <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                Home
            </Link>
              </li>
              <li>
            <Link to="services" spy={true} smooth={true}>
                Services
            </Link>              
              </li>
              
              <li>
            <Link to="experience" spy={true} smooth={true}>
                Experience
            </Link>
              </li>
              
              <li>
            <Link to="portofolio" spy={true} smooth={true}>
                Portofolio
            </Link>
              </li>
              
              <li>
            <Link to="testimonials" spy={true} smooth={true}>
                Testimonials
            </Link>
              </li>
              
            </ul>
          </div>
          <a href="https://api.whatsapp.com/send/?phone=6283150043326&text&type=phone_number&app_absent=0" target='_blank'>
          <button className="button n-button">
            Contact
          </button>
          </a>
        </div>
    </div>
  )
}

export default Navbar