import React from 'react'
import './Works.css'
import Puhua from "../../img/puhua.png";
import Maximum from "../../img/maximum.png";
import Dejavato from "../../img/dejavato.png";
import Jatis from "../../img/jatis.png";
import Educourse from "../../img/Educourse.png";
import { themeContext } from '../../Context';
import { useContext } from "react";
import { motion } from 'framer-motion';


const Works = () => {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="works">
        <div className="awesome">
            <span style={{color: darkMode? 'white': ''}}>Works for all these</span>
            <span>Companies & Clients</span>
            <spane>Highlighted company that have been works with me
            </spane>
            
            <button className="button s-button">Download CV</button>
            
            <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>

        {/* right side */}
        <div className="w-right">
          <motion.div 
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle">

            <div className="w-secCircle">
              <img src={Puhua} alt="" />
            </div>

            <div className="w-secCircle">
              <img src={Jatis} alt="" />
            </div>

            <div className="w-secCircle">
              <img src={Educourse} alt="" />
            </div>

            <div className="w-secCircle">
              <img src={Maximum} alt="" />
            </div>

            <div className="w-secCircle">
              <img src={Dejavato} alt="" />
            </div>

          </motion.div>

          {/* Background Circle */}
          <div className="w-backCircle blueCircle"></div>
          <div className="w-backCircle yellowCircle"></div>
        </div>
    </div>
  );
};

export default Works;