import React from 'react'
import './Footer.css'
import Wave from "../../img/wave.png"
import Instagram from '@iconscout/react-unicons/icons/uil-instagram'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Web from '@iconscout/react-unicons/icons/uil-blogger'

const Footer = () => {
  return (
    <div className="footer">
        <img src={Wave} alt="" style={{width: '100%'}} />
        <div className="f-content">
            <span>asksindhunugroho@gmail.com</span>
            <div className="f-icons">
            <a href='https://github.com/sindhu-nugroho' target='_blank'>

              <Github color='white' size='3rem'/>
            </a>

            <a href="https://www.linkedin.com/in/sindhu-nugroho-s-kom-725755100/" target='_blank'>

              <Linkedin color='white' size='3rem'/>
            </a>
            <a href="https://instagram.com/sindhunugroho_?igshid=ZDdkNTZiNTM=" target='_blank'>
              <Instagram color='white' size='3rem'/>
            </a>
                
            <a href="https://sindhutechno.blogspot.com/" target='_blank'>

                <Web color='white' size='3rem'/>
            </a>
            </div>
            <span>Copyright © Sindhu Nugroho. All Right Reserved</span>
        </div>
    </div>
  )
}

export default Footer