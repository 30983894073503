import React from 'react'
import './Portofolio.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import Maximum from "../../img/maxs.png";
import Sindhu from "../../img/sins.png";
import Sindhu2 from "../../img/sins2.png";
import IT from "../../img/it.png";
import 'swiper/css'
import { themeContext } from '../../Context';
import { useContext } from "react";



const Portofolio = () => {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
    <div className="portofolio" id='portofolio'>

        {/* heading */}
        <span style={{color: darkMode? 'white': ''}}>Recent Projects</span>
        <span>Portofolio</span>

        {/* slider */}
        <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className='portofolio-slider'
        >
            <SwiperSlide>
                <img src={Maximum} alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src={Sindhu} alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src={Sindhu2} alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src={IT} alt="" />
            </SwiperSlide>
        </Swiper>
    </div>
  )
}

export default Portofolio